import React, { useEffect, useState } from 'react'
import "./nba.scss"
import { Link } from 'react-router-dom'
import { get, ref } from 'firebase/database'
import { tickets } from "../../firebase-config"

export default function Mba() {
    const app = "nba"
    const version = "v2"
    const [description, setDescription] = useState("")
    const [short, setShort] = useState("")
    const [title, setTitle] = useState("")
    useEffect(() => {
        const dbRef = ref(tickets, "storepresentations/" + app + "/en/" + version)
        get(dbRef).then(snap => {
          setDescription(snap.val()?.description)
          setShort(snap.val()?.short ?? "")
          setTitle(snap.val()?.title ?? "")
    
        })
      }, [app, version])
    return (
        <div className='nba'>
            <div className="navbar">
                <div className="navitem"><Link to="./">About</Link></div>
                <div className="navitem"><Link to="./terms">Terms</Link></div>
                <div className="navitem"><Link to="./privacy">Privacy Policy</Link></div>
            </div>
            <div className="title">{title}</div>
            <div className="short">{short}</div>
            <div className="description">{description}</div>
        </div>
    )
}
