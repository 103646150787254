import { BrowserRouter as Router, Routes, Route, Outlet } from "react-router-dom";
import './App.css';
import Home from "./pages/home/Home";
import Sw from "./pages/sw/Sw";
import Privacy from "./pages/common/privacy/Privacy";
import Terms from "./pages/common/terms/Terms";
import Ca from "./pages/ca/Ca";
import We from "./pages/we/We";
import Mba from "./pages/mba/Mba";
import Nba from "./pages/nba/Nba";
import Swa from "./pages/swa/Swa";
import Support from "./pages/common/support/Support";
import Su from "./pages/su/Su";

function App() {
  return (
    <div className="App">
      <Router>
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/sw" element={<Sw />} />
      <Route path="/ca" element={<Ca />} />
      <Route path="/we" element={<We />} />
      <Route path="/su" element={<Su />} />
      <Route path="/mba" element={<Mba />} />
      <Route path="/nba" element={<Nba />} />
      <Route path="/swa" element={<Swa />} />
      <Route path="/sw/privacy" element={<Privacy appLong="Swinda"/>} />
      <Route path="/ca/privacy" element={<Privacy appLong="Catscha"/>} />
      <Route path="/we/privacy" element={<Privacy appLong="WE"/>} />
      <Route path="/su/privacy" element={<Privacy appLong="Sudoku"/>} />
      <Route path="/mba/privacy" element={<Privacy appLong="myBOY (Android)"/>} />
      <Route path="/nba/privacy" element={<Privacy appLong="nextBOY (Android)"/>} />
      <Route path="/swa/privacy" element={<Privacy appLong="Swinda (Android)"/>} />
      <Route path="/sw/terms" element={<Terms  appLong="Swinda"/>} />
      <Route path="/sw/support" element={<Support  appLong="Swinda"/>} />
      <Route path="/ca/support" element={<Support  appLong="Catscha"/>} />
      <Route path="/su/support" element={<Support  appLong="Sudoku"/>} />
      <Route path="/mba/support" element={<Support  appLong="myBOY (Android)"/>} />
      <Route path="/nba/support" element={<Support  appLong="nextBOY (Android)"/>} />
      <Route path="/swa/support" element={<Support  appLong="Swinda (Android)"/>} />
      <Route path="/ca/terms" element={<Terms  appLong="Catscha"/>} />
      <Route path="/su/terms" element={<Terms  appLong="Sudoku"/>} />
      <Route path="/we/terms" element={<Terms  appLong="WE"/>} />
      <Route path="/mba/terms" element={<Terms  appLong="myBOY (Android)"/>} />
      <Route path="/nba/terms" element={<Terms  appLong="nextBOY (Android)"/>} />
      <Route path="/swa/terms" element={<Terms  appLong="Swinda (Android)"/>} />
      </Routes>
      </Router>
      
    </div>
  );
}

export default App;
