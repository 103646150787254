

import { initializeApp } from "firebase/app";
import { getDatabase  } from 'firebase/database';
import { getAuth  } from 'firebase/auth';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAoSJqK2MZ0ZISBu3mTlDdw18Mop7MkA_k",
  authDomain: "dashboard-de8e7.firebaseapp.com",
  databaseURL: "https://dashboard-de8e7.firebaseio.com",
  projectId: "dashboard-de8e7",
  storageBucket: "dashboard-de8e7.appspot.com",
  messagingSenderId: "529172679885",
  appId: "1:529172679885:web:d19b85749c476eb6732d84",
  measurementId: "G-DM59LSCLPW"
};


const firebaseConfigAnalytics = {
  apiKey: "AIzaSyA4meGnmwlX1aDMHZk9YBiRdSu3celfxuc",
  authDomain: "analytics-394e7.firebaseapp.com",
  databaseURL: "https://analytics-394e7-default-rtdb.firebaseio.com",
  projectId: "analytics-394e7",
  storageBucket: "analytics-394e7.appspot.com",
  messagingSenderId: "345393928403",
  appId: "1:345393928403:web:7d7b775ae6714c2de29c26",
  measurementId: "G-M9Q6RCK7H7"
};
const firebaseConfigRevenueCat = {
  apiKey: "AIzaSyA62O7yHUD1R4m13dvk6BUWQikZKbaljm4",
  authDomain: "revenuecat-891ef.firebaseapp.com",
  databaseURL: "https://revenuecat-891ef-default-rtdb.firebaseio.com",
  projectId: "revenuecat-891ef",
  storageBucket: "revenuecat-891ef.appspot.com",
  messagingSenderId: "716554972956",
  appId: "1:716554972956:web:30798a02760e067d8e94d9",
  measurementId: "G-SGP2PK6K4W"
};

const firebaseConfigLogger2 = {
  apiKey: "AIzaSyB4CCBjzMeowNBwTYgZEFb9WdslM5wyR-A",
  authDomain: "logger2-99db0.firebaseapp.com",
  databaseURL: "https://logger2-99db0-default-rtdb.firebaseio.com",
  projectId: "logger2-99db0",
  storageBucket: "logger2-99db0.appspot.com",
  messagingSenderId: "771396078337",
  appId: "1:771396078337:web:b9eae7d896b268e3fc2900",
  measurementId: "G-J9LS0ZGJ4J"
};
const firebaseConfigTodos = {
  apiKey: "AIzaSyDx0fxKKqPaHK93mCQRP-vt2H67xi5TlCo",
  authDomain: "react-todo-50145.firebaseapp.com",
  databaseURL: "https://react-todo-50145-default-rtdb.firebaseio.com",
  projectId: "react-todo-50145",
  storageBucket: "react-todo-50145.appspot.com",
  messagingSenderId: "622350195141",
  appId: "1:622350195141:web:6f52f161887df5fb7a53ac",
  measurementId: "G-FF443NC7V6"
};
const firebaseConfigNutrition = {
  apiKey: "AIzaSyDO9Oc7RuTLovpUwRMJuFtQVdb53IbRN_0",
  authDomain: "nutrition-652d6.firebaseapp.com",
  databaseURL: "https://nutrition-652d6-default-rtdb.firebaseio.com",
  projectId: "nutrition-652d6",
  storageBucket: "nutrition-652d6.appspot.com",
  messagingSenderId: "829112028348",
  appId: "1:829112028348:web:faba6b18ef064a578fd4b9",
  measurementId: "G-K3RKZZTB1N"
};
const firebaseConfigStats = {
  apiKey: "AIzaSyDZUkjuQCsXxTM_LFCjSOn2zrvYZeNcO6U",
  authDomain: "stats-66195.firebaseapp.com",
  databaseURL: "https://stats-66195-default-rtdb.firebaseio.com",
  projectId: "stats-66195",
  storageBucket: "stats-66195.appspot.com",
  messagingSenderId: "1013228098221",
  appId: "1:1013228098221:web:a75285f0a2d6562df76c24",
  measurementId: "G-SFL7D0VP7Y"
};
const firebaseConfigTickets = {
  apiKey: "AIzaSyCAlc5ONOMcO3_onUh06JjUodLlDhWlbm0",
  authDomain: "tickets-bf977.firebaseapp.com",
  databaseURL: "https://tickets-bf977-default-rtdb.firebaseio.com",
  projectId: "tickets-bf977",
  storageBucket: "tickets-bf977.appspot.com",
  messagingSenderId: "6269238069",
  appId: "1:6269238069:web:2dc6572e79fa8b824d6a7c",
  measurementId: "G-5T543YQ8KX"
};
const firebaseConfigITCStats = {
  apiKey: "AIzaSyChn_kvXIenQ9xf6D-43DbyL2nkQ9iCXMQ",
  authDomain: "itcstats.firebaseapp.com",
  databaseURL: "https://itcstats-default-rtdb.firebaseio.com",
  projectId: "itcstats",
  storageBucket: "itcstats.appspot.com",
  messagingSenderId: "565019408241",
  appId: "1:565019408241:web:cb9b0961c17474544128d0",
  measurementId: "G-LRFLW145J0"
};

const firebaseConfigLogger3 = {
  apiKey: "AIzaSyBv6kxm7J-ODb495-cFhNxXMeiw9mrb8ag",
  authDomain: "logger3-fc715.firebaseapp.com",
  databaseURL: "https://logger3-fc715-default-rtdb.firebaseio.com",
  projectId: "logger3-fc715",
  storageBucket: "logger3-fc715.appspot.com",
  messagingSenderId: "82141781069",
  appId: "1:82141781069:web:ebf371c63de03e62a09004",
  measurementId: "G-VSW7XXH2T0"
}
const firebaseConfigSensortower = {
  apiKey: "AIzaSyBRj9iBUA7bm0wD5OpdrQQuGZR4kH8HDwY",
  authDomain: "sensortower-1629d.firebaseapp.com",
  databaseURL: "https://sensortower-1629d.firebaseio.com",
  projectId: "sensortower-1629d",
  storageBucket: "sensortower-1629d.appspot.com",
  messagingSenderId: "150861960508",
  appId: "1:150861960508:web:171d8498fec4e28b49fa39",
  measurementId: "G-7HC3JWPMRJ"
}


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);
export const dbDashbaord = database;
export const auth = getAuth(app);

export const analytics = getDatabase(initializeApp(firebaseConfigAnalytics,'analytics'))
export const stats = getDatabase(initializeApp(firebaseConfigStats,'stats'))
export const itcstats = getDatabase(initializeApp(firebaseConfigITCStats,'itcstats'))
export const logger2 = getDatabase(initializeApp(firebaseConfigLogger2,'logger2'))
export const revenuecat = getDatabase(initializeApp(firebaseConfigRevenueCat,'revenuecat'))
export const todos = getDatabase(initializeApp(firebaseConfigTodos,'todos'))
export const nutrition = getDatabase(initializeApp(firebaseConfigNutrition,'nutrition'))
export const tickets = getDatabase(initializeApp(firebaseConfigTickets,'tickets'))
export const logger3 = getDatabase(initializeApp(firebaseConfigLogger3,'logger3'))
export const sensortower = getDatabase(initializeApp(firebaseConfigSensortower,'sensortower'))
