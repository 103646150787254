import React from 'react'
import { Link } from 'react-router-dom'
import "./terms.scss"
export default function Terms({appLong}) {

  const kTerms = "We want to encourage users to have nice and beautiful profiles. We do not accept sexually explicit content or nudity of any kind. No user under the age of 18 may use this app. Therefore profile pictures will be reviewed by a moderator and be permanantly deleted if found inappropiate. We also do not tolerate objectionable chat-content. Inappropiate of abusive profiles or users that do not respect these guidelines will be permanently deleted. Users can contact support under marlin.brandin@yahoo.com."
  return (
    <div className='terms'>
                  <div className="navbar">
                <div className="navitem"><Link to="../">Home</Link></div>
            
            </div>
      Terms for {appLong}
      <div className="description">{kTerms}</div>
   
    </div>
  )
}
